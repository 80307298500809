@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

body {
  font-family: 'Raleway', sans-serif;
  background-color: #fcfcfc;
}

.spinner-border {
  width: 100px;
  height: 100px;
}

.loading-indicator {
  margin-left: 45%;
  margin-right: 45%;
  margin-top: 25%;
}

.footer-version {
  font-size: 0.8rem;
}

.header {
  background-color: #e8ff65;
}

.profile-item {
  width: 170px !important;
}

.home-background {
  height: 700px;
  /* background-image: url(./images/SBELogo300dpi.jpg);*/
  background-size: 100%;
  background-repeat: no-repeat;
}

.chat-area,
.profile-form-section {
  padding-top: 70px;
}

.chat-area {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}

.chat-area .chat-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.chat-bubble .chat-time {
  font-size: 12px;
  color: #757575;
  text-align: right;
}

.chat-area .chat-bubble.current-user {
  margin-left: auto;
  background-color: #a5d6a7;
}

.form-control {
  width: 90%;
}

.form-control:focus {
  box-shadow: none;
}

.btn {
  margin-top: 10px;
}

.btn-submit {
  background-color: #2e7d32;
  color: white;
}

@media screen and (max-width: 767px) {
  .form-control {
    width: 100%;
  }

  .chat-area .chat-bubble {
    max-width: 250px;
  }
}

.contact-details-sliding-pane {
  margin-top: 80px;
}

.form_label {
  font-size: small;
}

.chat-section {
  max-width: 70%;
}

.profiles-section {
  max-width: 20%;
  text-align: right;
  background-color: #a7d6fd;
}

/* radio buttons to switch buttons  */

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: 7px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #a5dc86;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}
